<template>
  <div class="view-roleEmployees">
    <div v-if="label" class="view-roleEmployees__title">
      <h4>{{ label }}</h4>
    </div>
    <div class="view-roleEmployees__content">
      <v-chip-group column>
        <v-chip v-for="employee in value" :key="employee.id" :to="getEmployeeRoute(employee)" color="#e6ebff">
          {{ employee.firstName }} {{ employee.lastName }}
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
import { EMPLOYEES_DETAILED } from '@/constants/routes';

export default {
  name: 'ViewFieldRoleUsers',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
  },

  methods: {
    getEmployeeRoute(employee) {
      console.log(this.$route);
      return {
        name: EMPLOYEES_DETAILED,
        params: { id: employee.id, prevPageRoute: { name: this.$route.name, params: { id: this.$route.params.id } } },
      };
    },
  },
};
</script>

<style lang="scss">
.view-roleEmployees {
  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
